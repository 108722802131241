import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.prototype.$isWeiXin = (function () {
  let ua = window.navigator.userAgent.toLowerCase()
  //通过正则表达式匹配ua中是否含有MicroMessenger字符串
  if (ua.match(/MicroMessenger/i) == 'micromessenger') {
    return true
  } else {
    return false
  }
})()

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/home',
  },
  {
    name: 'home',
    path: '/home',
    component: () => import('@/pages/home/index'),
  },
  {
    name: 'configure',
    path: '/configure',
    component: () => import('@/pages/home/config'),
  },
  // {
  //     name: "bind",
  //     path: "/bind",
  //     component: () => import("@/pages/bind/index")
  // },
  // {
  //     name: "login",
  //     path: "/login",
  //     component: () => import("@/pages/login")
  // },
  // {
  //     name: "register",
  //     path: "/register",
  //     component: () => import("@/pages/register")
  // },
  // {
  //     name: "forget",
  //     path: "/forget",
  //     component: () => import("@/pages/forget")
  // },
  {
    name: 'pay',
    path: '/pay',
    component: () => import('@/pages/pay/pay'),
  },
  {
    name: 'payLog',
    path: '/payLog',
    component: () => import('@/pages/pay/log'),
  },
  {
    name: 'selectObject',
    path: '/selectObject',
    component: () => import('@/pages/pay/selectObject'),
  },
  {
    name: 'order',
    path: '/order',
    component: () => import('@/pages/pay/order'),
  },
  {
    name: 'alipay',
    path: '/alipay',
    component: () => import('@/pages/pay/alipay'),
  },
  {
    name: 'equipment',
    path: '/equipment',
    component: () => import('@/pages/equipment/index'),
  },
  {
    name: 'usage',
    path: '/usage',
    component: () => import('@/pages/usage/index'),
  },
  {
    name: 'usageUser',
    path: '/usageUser',
    component: () => import('@/pages/usage/usageUser'),
  },
  {
    name: 'selectEquip',
    path: '/selectEquip',
    component: () => import('@/pages/usage/select'),
  },
  {
    name: 'config',
    path: '/config',
    component: () => import('@/pages/config/index'),
  },
  {
    name: 'control',
    path: '/control',
    component: () => import('@/pages/control/index'),
  },
  {
    name: 'handleControl',
    path: '/handleControl',
    component: () => import('@/pages/control/control'),
  },
  {
    name: 'auth',
    path: '/auth',
    component: () => import('@/pages/auth'),
  },
  {
    name: 'power',
    path: '/power',
    component: () => import('@/pages/power/select'),
  },
  {
    name: 'powerChart',
    path: '/powerChart',
    component: () => import('@/pages/power/power'),
  },
]

const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch((err) => err)
}

const routs = new VueRouter({ routes: routes })
const whiteList = ['/alipay']
const blackList = [
  '/control',
  '/handleControl',
  '/selectEquip',
  '/usage',
  '/config',
  '/power',
  '/powerChart',
]
routs.beforeEach((to, form, next) => {
  // 信息学校roleId
  localStorage.setItem('roleKey', 2)
  let userId = localStorage.getItem('appUserId')
  let showControl = localStorage.getItem('showControl')

  let roleKey = localStorage.getItem('roleKey')
  let openid = localStorage.getItem('openId')

  // 白名单
  if (whiteList.includes(to.path)) {
    next()
  } else {
    // 未登录，跳转登录页面
    let object = parseUrl()
    // console.log(object, "跳转带过来的参数")
    if (object.appUserId) {
      // localStorage.setItem("token", object.token)
      localStorage.setItem('appUserId', object.appUserId)
      localStorage.setItem('phoneNum', object.phoneNum)
      localStorage.setItem('roleIds', object.roleIds)
      if (object.roleIds && object.roleIds.includes('2')) {
        localStorage.setItem('showControl', 'true')
      } else {
        localStorage.setItem('showControl', 'false')
      }
      // window.location.href = window.origin + window.location.pathname
    }
    if (!userId) {
      window.location.href = window.casServerUrl
    }

    if (!userId && to.path != '/home') {
      next('/home')
    }

    // 进入登录页面，如果已登录，跳转首页
    if (to.path == '/login' && userId) {
      next('/home')
    }

    // 已登录未绑定项目，跳转绑定项目页面
    if (to.path != '/bind' && userId && !roleKey) {
      next('/bind')
    }

    // 如果已登录并绑定项目，但未授权，跳转授权页面
    if (
      (to.path == '/order' || to.path == '/pay') &&
      !openid &&
      roleKey &&
      Vue.prototype.$isWeiXin
    ) {
      next('/auth')
    }

    // 没有管理员权限进入管理页面，直接跳转首页
    if (blackList.includes(to.path) && showControl == 'false') {
      next('/home')
    }

    next()
  }
})

function parseUrl() {
  let url = window.location.href
  let query = url.split('#')[0]
  let params = query.split('?')
  if (params[1]) {
    let arr = params[1].split('&')
    let result = {}
    for (let i = 0; i < arr.length; i++) {
      result[arr[i].split('=')[0]] = arr[i].split('=')[1]
    }
    return result
  } else {
    return {}
  }
}

export default routs
