import Vue from 'vue'
import App from './App.vue'

import router from "./router"
import moment from "moment";
import axios from "./request";
import * as echarts from "echarts"

// import VConsole from "vconsole";
// const vs = new VConsole()
// Vue.use(vs)

import WotDesign from 'wot-design'
import 'wot-design/lib/theme-default/index.css'
Vue.use(WotDesign)

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

Vue.config.productionTip = false

Vue.prototype.$http = axios
Vue.prototype.$echarts = echarts
Vue.prototype.$moment = moment
Vue.prototype.$goBack = () => {
  router.go(-1)
}

Vue.prototype.$goHome = () => {
  router.push('/home')
}

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')

// 加入百度统计
var _hmt = _hmt || [];
window._hmt = _hmt; // 必须把_hmt挂载到window下，否则找不到
(function () {
  var hm = document.createElement("script");
  hm.src = "https://hm.baidu.com/hm.js?" + "1b4bb6503a88d81ad1484cc92e335253";
  var s = document.getElementsByTagName("script")[0];
  s.parentNode.insertBefore(hm, s);
})();

router.beforeEach((to, from, next) => {
  if (to.path) {
    if (window._hmt) {
      window._hmt.push(['_trackPageview', '/#' + to.fullPath])
    }
  }
  next()
})
