<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: 'App',
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body{
  margin: 0;
  padding: 0;
  border: 0;
  list-style: none;
  text-decoration: none;
  color: inherit;
  font-weight: normal;
  font-family: "微软雅黑";
  box-sizing: border-box;
  font-style: normal;
}
</style>
